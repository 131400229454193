import React from 'react';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import {twMerge} from '@/stylesheets/twMerge';

export interface TwoColumnGridProps {
  children: React.ReactNode;
  className?: string;
  componentName?: string;
}

export interface TwoColumnColProps {
  children?: React.ReactNode;
  className?: string;
  withGutter?: boolean;
}

export const colSpanProps = (withGutter?: boolean) => ({
  xs: 4,
  sm: withGutter ? 6 : 8,
  md: withGutter ? 5 : 6,
});
export const col1Start = 1;
export const col2Start = (withGutter?: boolean) => ({
  xs: 1,
  sm: 1,
  md: withGutter ? 8 : 7,
});
export function TwoColumn({
  children,
  componentName,
  className,
}: TwoColumnGridProps) {
  return (
    <Grid
      data-component-name={componentName}
      className={twMerge('gap-y-xl', className)}
    >
      {children}
    </Grid>
  );
}

TwoColumn.Col1 = function TwoColumnCol1({
  children,
  className,
  withGutter = false,
}: TwoColumnColProps) {
  return (
    <Col
      className={twMerge('flex flex-col gap-y-xl', className)}
      start={col1Start}
      span={colSpanProps(withGutter)}
    >
      {children}
    </Col>
  );
};

TwoColumn.Col2 = function TwoColumnCol2({
  children,
  className,
  withGutter = true,
}: TwoColumnColProps) {
  return (
    <Col
      className={twMerge('flex flex-col gap-y-xl', className)}
      start={col2Start(withGutter)}
      span={colSpanProps(withGutter)}
    >
      {children}
    </Col>
  );
};
